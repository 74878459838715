.content {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: row;

  @media (--tablet) {
    flex-direction: column;
  }
}

.imgContainer {
  margin-right: 1.6rem;

  @media (--tablet) {
    margin-right: 0;
    margin-bottom: 1.6rem;
    width: 12rem;
  }
}

.img {
  width: 100%;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.flex {
  flex: 1;
  position: relative;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: var(--color--grey-dark);
  }
}

.section {
  margin-bottom: 2.4rem;
}

.title {
  display: block;
  color: var(--color--grey-dark);
  font-size: var(--font-size--title);
  font-weight: 500;
}

.value {
  display: block;
  color: var(--color--white);
  font-size: var(--font-size--title);
}
