.container {
  margin: 1.6rem;
}

.header {
  text-align: center;
}

.title {
  display: block;
  font-size: var(--font-size--title);
  font-weight: 700;
  margin: 2.4rem 1.6rem;
}

.subtitle {
  display: block;
  margin-bottom: 1.6rem;
}
