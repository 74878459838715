.container {
  margin: 1.6rem;
}

.loader {
  text-align: center;
}

:global #greenid-intro-content > h1 {
  display: none;
}

:global .greenid-datepicker-container {
  color: var(--color--black);
}
