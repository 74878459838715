.container {
  margin: 1.6rem;
}

.activatedContainer {
  text-align: center;
  padding: 0.8rem 0;
}

.checkmark {
  width: 4.8rem;
  height: 4.8rem;
}
