.container {
  background-color: var(--color--white);
  position: fixed;
  inset: 0;
  color: var(--color--black);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
}

.logo {
  height: 4.8rem;
}

.content {
  margin: 1.6rem;
}

.title {
  text-align: center;
}

.list {
  max-width: 60rem;
  padding: 0;
  margin: 1.6rem auto;
  list-style: none;

  & > li {
    border-bottom: 1px solid var(--color--black);
  }
}

.button {
  width: 100%;
  height: 6.4rem;
}

.buttonContent {
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.urgent {
  color: var(--color--warning);
}

.img {
  height: 4rem;
  margin-right: 0.8rem;
}

.chevron {
  width: 1.6rem;
  height: 1.6rem;
  transform: rotate(-90deg);
}
