.bookieContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list > * {
  margin: 1.6rem 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.bookie {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.6rem;
}

.name {
  display: block;
  margin: 0 0.4rem;
  font-weight: 600;
}

.button {
  display: inline-block;
  background: none;
  border: none;
  padding: 0.4rem 0.8rem;
  width: 12rem;

  & > img {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
}

.label {
  display: inline-block;
  color: var(--color--grey-dark);
  width: 10rem;
}

.value {
  font-weight: 700;
}
