.tableContainer {
  overflow: auto;
  max-height: 40rem;
}

.table {
  border-collapse: collapse;
  background-color: var(--color--white);
  color: var(--color--black);
  border-radius: 0.4rem;
  width: 100%;
  margin-top: 0.8rem;

  & thead {
    position: sticky;
    top: 0;
    background-color: var(--color--white);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  }

  & th {
    padding: 0.8rem;
    text-align: left;
  }

  & td {
    padding: 0.4rem;
    
    &:first-child {
      white-space: nowrap;
    }
  }

  & tbody > *:not(:last-child) {
    border-bottom: 1px solid var(--color--grey);
  }
}
