.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color--primary);
  height: 8rem;
  padding: 0 1.6rem;
}

.logoContainer {
  height: 4.8rem;
}

.logo {
  height: 100%;
}

.actionsContainer {
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.footer {
  padding: 4.8rem 1.6rem;
  background-color: var(--color--primary-dark);
  background-color: var(--color--primary);
}
  
.footerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}
