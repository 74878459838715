.box {
  background-color: var(--color--primary-dark);
  padding: 1.6rem;
  margin: 1.6rem;
  border-radius: 8px;

  @media (--mobile) {
    margin: 0;
    border-radius: 0;
  }
}
